/* eslint-disable @typescript-eslint/no-explicit-any */
import { Static, Type } from "@sinclair/typebox"
import { Value } from "@sinclair/typebox/value"
import { ActionIcon } from "flex/components/button"
import useSnacks from "flex/hooks/useSnacks"
import {
  createContext,
  ReactElement,
  ReactNode,
  useCallback,
  useContext,
  useEffect,
  useRef,
} from "react"
import { asHTTPError } from "~/errors/http-request-error"
import { Reducer, usePartialState } from "~/hooks/use-partial-state"
import { useResource } from "~/hooks/use-resource"
import { ApiClient } from "~/services/aoeu"
import {
  FLEXBaseUnitPayloadBox,
  FLEXClassPayloadBox,
} from "~/services/aoeu/models.my-classes"
import logger from "~/util/logger"

export const MyClassesCtx = createContext({
  myClasses: [],
  districtClasses: [],
  unassignedUnits: [],
  updateMyClasses: () => {},
  reduceMyClasses: () => {},
  refreshMyClasses: () => Promise.resolve({} as WithStatus<MyClassesData>),
  status: "pre-init",
} as MyClassesContext)

export const MyClassesProvider = ({
  children,
  _timestamp,
  ...myClassesData
}: {
  children: ReactNode
  _timestamp?: number
} & MyClassesData): ReactElement => {
  /**
   * Since any pageProps can be passed to the provider,
   * we need to check for the keys we care about.
   * If not, establish that we are in pre-init status.
   * This will let any consumers of the context know
   * that data will need to be fetched.
   */
  const keysToCheck = ["myClasses", "districtClasses", "unassignedUnits"]
  const hasClassDataFromProps = keysToCheck.every(k => k in myClassesData)
  const initialStatus = hasClassDataFromProps ? "ready" : ("pre-init" as Status)

  const { api } = useResource()
  const [state, updateMyClasses, reduceMyClasses] = usePartialState(() => ({
    ...Value.Cast(MyClassesDataBox, myClassesData),
    _timestamp,
    status: initialStatus,
  }))

  const refreshMyClasses = useCallback(async (): Promise<
    WithStatus<MyClassesData>
  > => {
    updateMyClasses({ status: "refreshing" })
    try {
      const {
        // eslint-disable-next-line no-shadow
        data: { myClasses, districtClasses, unassignedUnits },
      } = await withMyClasses(Promise.resolve({ data: {} }), api)
      const status = "ready"
      updateMyClasses({ myClasses, districtClasses, unassignedUnits, status })
      return { myClasses, districtClasses, unassignedUnits, status }
    } catch (e) {
      updateMyClasses({ status: "error" })
      throw e
    }
  }, [api, updateMyClasses])

  // if new class data is passed in as page props, update the context
  // this is when navigating to a new page that loads classes in getServerSideProps
  useEffect(() => {
    if (
      _timestamp &&
      (!state._timestamp || state._timestamp !== _timestamp) &&
      hasClassDataFromProps
    ) {
      updateMyClasses({
        ...Value.Cast(MyClassesDataBox, myClassesData),
        _timestamp,
        status: "ready",
      })
    }
  }, [
    _timestamp,
    hasClassDataFromProps,
    myClassesData,
    state._timestamp,
    updateMyClasses,
  ])

  return (
    <MyClassesCtx.Provider
      value={{
        ...state,
        updateMyClasses,
        refreshMyClasses,
        reduceMyClasses,
      }}>
      {children}
    </MyClassesCtx.Provider>
  )
}

export function MyClassesRefreshButton({
  onStatusChange = () => {},
  onRefresh = () => {},
}: {
  onStatusChange?: (s: Status) => void
  onRefresh?: (d: MyClassesData) => void
}): ReactElement {
  const { status, refreshMyClasses } = useMyClasses()
  const { showError } = useSnacks()
  const firstRender = useRef(false)
  useEffect(() => {
    if (!firstRender.current) {
      onStatusChange(status)
    }
    firstRender.current = true
  }, [status])
  return (
    <ActionIcon
      active={status === "refreshing"}
      tooltip={"Refresh Class List"}
      onClick={() => {
        refreshMyClasses()
          .then(onRefresh)
          .catch(e => {
            const error = asHTTPError(e)
            showError(error.message)
            showError("Failed to refresh my classes")
            logger.error(e)
          })
      }}
    />
  )
}

export const useMyClasses = (): MyClassesContext => useContext(MyClassesCtx)

/**
 * Optional configuration for the `withMyClasses` function.
 * */
type TWithMyClassesOptions = {
  /**
   *  If provided, the result of the wrapped promise will be nested under this key in the returned data object.
   *
   * @example
   * ```typescript
   * const result = await withMyClasses(apiCall, api, { propsKey: 'customKey' });
   * console.log(result.data.customKey); // Access the data under the custom key
   * ```
   * */
  propsKey?: string
}

type WithMyClassesReturnType<D, K extends string | undefined> = K extends string
  ? { [P in K]: D } & MyClassesData
  : D & MyClassesData

export async function withMyClasses<
  D extends { data: any },
  T extends Promise<D> | AsyncClosure<D>,
  K extends string | undefined = undefined,
>(
  wrapped: T,
  api: ApiClient,
  options?: TWithMyClassesOptions & { propsKey?: K },
): Promise<{ data: WithMyClassesReturnType<D["data"], K> }> {
  const [
    result,
    {
      data: {
        my_classes: myClasses,
        district_classes: districtClasses,
        my_units: unassignedUnits,
      },
    },
  ] = await Promise.all([
    typeof wrapped === "function" ? wrapped() : wrapped,
    api.listClasses(),
  ])

  removeUnusedClassData(myClasses)
  removeUnusedClassData(districtClasses)
  removeUnusedClassData(unassignedUnits)

  return {
    data: {
      ...(options?.propsKey
        ? { [options.propsKey]: (result as D).data }
        : { ...(result as D).data }),
      myClasses,
      districtClasses,
      unassignedUnits,
    },
  }
}
export type MyClassesData = Static<typeof MyClassesDataBox>
export const MyClassesDataBox = Type.Object(
  {
    districtClasses: Type.Array(FLEXClassPayloadBox),
    myClasses: Type.Array(FLEXClassPayloadBox),
    unassignedUnits: Type.Array(FLEXBaseUnitPayloadBox),
  },
  { additionalProperties: false },
)
type Status = "pre-init" | "ready" | "refreshing" | "error"
export type MyClassesContext = WithStatus<MyClassesData> & {
  updateMyClasses: (c: Partial<WithStatus<MyClassesData>>) => void
  reduceMyClasses: (r: Reducer<WithStatus<MyClassesData>>) => void
  refreshMyClasses: () => Promise<WithStatus<MyClassesData>>
}

type Awaited<P extends Promise<any>> = P extends Promise<infer T> ? T : never
type AsyncClosure<R> = () => Promise<R>
type WithStatus<T> = T & {
  status: Status
}

export function removeUnusedClassData(
  classes:
    | Array<Static<typeof FLEXClassPayloadBox>>
    | Array<Static<typeof FLEXBaseUnitPayloadBox>>
    | undefined,
): void {
  classes?.forEach((myClass: any) => {
    deleteUnused(myClass, ["id", "title", "description", "edges", "assets"])
    myClass.assets?.forEach((asset: any) => {
      deleteUnused(asset, ["id"])
    })

    const units = myClass.edges?.units ?? []
    units.forEach((unit: any) => {
      deleteUnused(unit, ["id", "title", "description", "assets"])
      unit.assets?.forEach((asset: any) => {
        deleteUnused(asset, ["id"])
      })
    })
  })
}

function deleteUnused(
  data: Static<typeof FLEXClassPayloadBox>,
  allowedKeys: Array<keyof Static<typeof FLEXClassPayloadBox>>,
) {
  Object.keys(data).forEach(key => {
    const k = key as keyof Static<typeof FLEXClassPayloadBox>
    if (!allowedKeys.includes(k)) {
      delete data[k]
    }
  })
}
